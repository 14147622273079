/*
 * @Author: jiameng15
 * @Date: 2021-07-12 10:43:04
 * @Last Modified by: jiameng15
 * @Last Modified time: 2024-06-24 11:11:17
 * 聆课-图片上传
 */
import request from "@/utils/request"; // 引入request,基于axios
// import queryString from "querystring"; // post方法传参转型String
/**
 * 1.图片上传
 */
export function postImgUpload(data) {
  return request({
    url: "/upload/url",
    method: "post",
    data: data
  });
}