<!-- 登录页 -->
<template>
  <div class="container order_list ignore login" ref="container">
    <transition name="van-fade">
      <div v-if="!firstCome">
        <div class="first_come" v-show="!firstCome">
          <!-- <img class="bg-class" src="@/assets/images/welcome/bg.png" alt="" /> -->
          <!-- v-if="!isNotWX && !code"  -->
          <div class="first_title" v-if="!isNotWX">
            <img
              class="logo_class"
              src="@/assets/images/welcome/logo.png"
              alt
            />
            <!-- <img src="@/assets/images/welcome/arrow_right.png" alt="" />
            <span class="sign_in">微信登录</span>
            <img src="@/assets/images/welcome/arrow_left.png" alt="" />-->
            <div @click.stop="getWxCode" class="first_title1">微信授权登录</div>
            <div class="first_title2 margin-t-20">
              <!-- <van-checkbox v-model="checked" checked-color="#e49f54"></van-checkbox> -->
              <!-- <div @click.stop="checked = !checked"> -->
              登录即代表同意
            </div>
            <div class="first_title2 margin-t-20">
              <span class="protocol" @click.stop="toProtocol"
                >《聆课咨询学习平台用户使用协议》</span
              >
            </div>
          </div>
          <!-- <div class="first_title" style="bottom:10vh"  @click="removeStore('signatureInfo')">
            清空store
          </div>-->
          <!-- <div class="welcome_content" v-if="!isNotWX && code">
            <div>欢迎回来</div>
            <div>{{ userInfo.wxNickname }}</div>
          </div>-->
        </div>
      </div>
      <div v-else v-show="firstCome" class="bind_tel_wrap">
        <div class="logo">
          <div class="back" @click="goBack">
            <img src="@/assets/images/home/back@2x.png" alt />
          </div>
          手机号绑定
        </div>
        <div class="bind_tel">
          <div class="user_header">
            <div class="sub_welcome">为了更好的服务体验，请绑定您的手机号</div>
            <div class="form_label margin-t-40">手机号</div>
            <div class="user_info border_bottom margin-t-20">
              <input
                ref="telephone"
                class="input_title"
                type="tel"
                placeholder="请输入手机号"
                maxlength="11"
                v-model="userInfo.telephone"
              />
            </div>
            <div class="form_label margin-t-40">验证码</div>
            <div style="display: flex; width: 100%" class="border_bottom">
              <div class="user_info margin-t-20 half_width">
                <input
                  ref="telephone"
                  class="input_title"
                  type="tel"
                  placeholder="请输入验证码"
                  maxlength="16"
                  v-model="userInfo.captcha"
                />
              </div>
              <div class="margin-t-20 half_width_sm">
                <van-button
                  plain
                  type="primary"
                  class="btn"
                  @click="getCaptcha"
                  :disabled="countDown > 0"
                  >{{
                    countDown > 0 ? `${countDown} s` : "获取验证码"
                  }}</van-button
                >
              </div>
            </div>
          </div>
          <div class="btn_class margin-t-80 margin-b-40">
            <van-button plain type="primary" class="btn" @click="bindPhoneFunc"
              >确认绑定</van-button
            >
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  login,
  wxlogin,
  getCaptcha,
  updateMyInfo,
  bindPhone,
} from "@/api/login";
import { postImgUpload } from "@/api/upload";
import { Toast, Dialog } from "vant";
import {
  getUserInfo,
  getKeyWords,
  putUserName,
  postUserKeyWords,
  getUserKeyWords,
} from "@/api/user";
import { setToken } from "@/utils/auth";
import {
  setStore,
  getStore,
  removeStore,
  getSessionStore,
  removeSessionStore,
  isWechatBroswer,
} from "@/utils/mUtils";
export default {
  name: "loginInfo",
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    return {
      showPop: false,
      countDown: 0,
      intervalId: null,
      isNotWX: false,
      code: "",
      file: null,
      option: {
        info: true,
        autoCropWidth: 180,
        autoCropHeight: 180,
        confirmButtonBackgroundColor: "#6A83FD",
        outputType: "png",
        outputSize: 1,
        enlarge: 1,
      },
      firstCome: false,
      showInput: false,
      checked: true,
      username: "",
      showPreview: true,
      fileList: [],
      point: {
        lv: 10000,
        current: 2860,
        oldCurrent: 0,
      },
      tags: [],
      chooseTags: [],
      fileShow: false,
      registerType: "",
      count: 0,
      isLoading: false,
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    // point:{
    //   handler(newValue, oldValue) {
    //     this.point.oldValue =
    //   },
    //   deep:true
    //   }
    // activeName(newValue, oldValue) {
    //   if (newValue != oldValue) {
    //     console.log(newValue);
    //     switch (newValue) {
    //       case "0":
    //         setStore("orderListType", "0");
    //         break;
    //       case "1":
    //         setStore("orderListType", "1");
    //         break;
    //     }
    //   }
    // },
  },
  // 方法集合
  methods: {
    redirectFunc() {
      if (getSessionStore("REDIRECT_INFO")) {
        let { path, query } = JSON.parse(getSessionStore("REDIRECT_INFO"));
        this.$router.push({
          path,
          query,
        });
      } else {
        this.$router.push({
          path: "/home",
        });
      }
      removeSessionStore("REDIRECT_INFO");
    },
    goBack() {
      window.location.href = this.$BaseUrl;
    },
    toProtocol() {
      window.history.pushState(null, "", "/");
      window.location.href =
        "https://online.lemlink.com/protocol/privacyPolicy.html";
    },
    async getCaptcha() {
      if (!this.userInfo.telephone) {
        Toast("请输入手机号");
        return false;
      }
      const res = await getCaptcha({ telephone: this.userInfo.telephone });
      this.countDown = 60; // 倒计时60秒
      this.intervalId = setInterval(() => {
        if (this.countDown > 0) {
          this.countDown -= 1;
        } else {
          clearInterval(this.intervalId);
        }
      }, 1000);
    },
    chooseTag(tag) {
      for (let index = 0; index < this.tags.length; index++) {
        if (this.tags[index].name === tag.name) {
          this.tags[index].isActive = !this.tags[index].isActive;
          if (this.tags[index].isActive) {
            this.chooseTags.push(tag);
          } else {
            console.log("false");
            for (let j = 0; j < this.chooseTags.length; j++) {
              if (this.chooseTags[j].name === tag.name) {
                console.log("xfs");
                this.chooseTags.splice(j);
              }
            }
          }
        }
      }
    },
    editUsername() {
      this.showInput = true;
      this.$nextTick(() => {
        this.$refs.username.focus();
      });
    },
    hiddenInput() {
      this.showInput = false;
      this.putUserNameFunc();
    },

    getWxCode() {
      const APPID = this.$AppId;
      const redUrl = encodeURIComponent(this.$BaseUrl + "welcome");
      console.log("看看redUrl", redUrl);
      location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APPID}&redirect_uri=${redUrl}&response_type=code&scope=snsapi_userinfo&state=welcome#wechat_redirect`;
      this.$EventBus.$emit("welcomeShow");
    },
    async bindPhoneFunc() {
      // 上传数据
      if (this.userInfo.captcha === "") {
        Toast("请输入验证码");
        return false;
      }
      const res = await bindPhone({
        captcha: this.userInfo.captcha,
        id: this.userInfo.id,
        telephone: this.userInfo.telephone,
      });
      if (res.code == 1) {
        Toast("绑定成功");
        setToken(res.data.token);
        setStore("userInfo", JSON.stringify(this.userInfo));
        this.redirectFunc();
      }
    },

    beforeRead(file) {
      if (!this.checkImgAccept(file)) {
        return false;
      }
      this.fileList = [];
      this.option.outputType = file.type.split("/")[1];
      this.showPreview = true;
      return true;
    },
    async afterRead(file) {
      console.log(file);
      this.file = file;
      this.$refs.cropper.loadFile(file.file);
    },
    transBase64(data) {
      this.file.content = data;
    },
    async upload(file) {
      this.file.status = "uploading";
      this.file.message = "上传中...";
      this.file.file = file;
      const formData = new FormData();
      formData.append("img_upload", file); // 要提交给后台的文件
      await postImgUpload(formData)
        .then((res) => {
          this.userInfo.avatar = res.data;
          this.file.status = "done";
          console.log("完成");
          setStore("userInfo", JSON.stringify(this.userInfo));
        })
        .catch((err) => {
          this.file.status = "failed";
          this.file.message = "上传失败";
        });
    },
    editHeadImg() {
      Toast("修改头像");
    },
    async getKeyWordsFunc() {
      const res = await getKeyWords();
      let { data } = res;
      const newTag = [];
      data.map((tag) => {
        const data = {
          name: tag,
          isActive: false,
        };
        newTag.push(data);
      });
      this.tags = newTag;
      this.getUserKeyWordsFunc();
    },
    async putUserNameFunc() {
      await putUserName({
        nickName: this.userInfo.nickname,
      });
      setStore("userInfo", JSON.stringify(this.userInfo));
    },
    async getUserInfoFunc() {
      this.userInfo = JSON.parse(getStore("userInfo"));

      const res = await getUserInfo();

      this.userInfo = res.data;

      if (!this.userInfo.nickname) {
        this.userInfo.nickname = this.userInfo.username;
      }
      setStore("userInfo", JSON.stringify(this.userInfo));
      this.isFirstCome(this.userInfo.isChoose);
      console.log("获取到了userInfo");
    },
    isFirstCome(isChoose) {
      if (isChoose === 0) {
        console.log("isf");
        this.getKeyWordsFunc();
        setTimeout(() => {
          this.firstCome = true;
          this.$EventBus.$emit("welcomeShow");
        }, 2000);
      } else {
        console.log("isnf");
        setTimeout(() => {
          // this.toHomePage();
        }, 2000);
      }
    },
    async getUserKeyWordsFunc() {
      const res = await getUserKeyWords();
      console.log(res);
      res.data.map((tag) => {
        for (let index = 0; index < this.tags.length; index++) {
          if (this.tags[index].name === tag) {
            this.tags[index].isActive = !this.tags[index].isActive;
            this.chooseTags.push(this.tags[index]);
          }
        }
      });
      console.log(this.chooseTags);
    },
    async getTokenFunc(code) {
      let res = null;
      if (process.env.VUE_APP_ENV === "development") {
        // res = await login({ id: 86 }); // 测试
        res = await wxlogin({ code });
        console.log(res);
      } else {
        res = await wxlogin({ code });
        console.log(res);
      }
      this.userInfo = res.data;
      // this.userInfo.next = 1 // 测试
      if (this.userInfo.next === 1) {
        // 需要绑定手机号
        console.log("需要绑定手机号");
        this.firstCome = true;
      } else {
        setToken(res.data.token);
        setStore("userInfo", JSON.stringify(this.userInfo));
        this.redirectFunc();
      }
    },
  },
  created() {
    console.log("created");
    // 判断是否是微信环境
    if (isWechatBroswer()) {
      this.isNotWX = false;
    } else {
      this.isNotWX = true;
      this.$router.push("/noAuth/notWx"); // 非微信环境 开发环境
    }
    // this.initWXJSSDKNoShare();
    // 记录需要跳转的路径
    // 判断是否有token 测试用
    if (this.$route.query.token) {
      let token = this.$route.query.token;
      setToken(token);
      this.redirectFunc();

      // }
    }
    if (getStore("token")) {
      this.redirectFunc();
    } else {
      // 判断是否有code
      if (this.$route.query.code) {
        this.code = this.$route.query.code;
        console.log(this.code);
        this.getTokenFunc(this.code);
      }
      // else{
      //   this.getWxCode()
      // }
    }
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    // console.log(process.env.VUE_APP_ENV);
    // console.log(this.$refs.container);
    // console.log(this.$refs.container.clientHeight);
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
// @import url(); 引入公共css类
@import "~@/styles/variable.scss";
.container.ignore {
  top: 0;
}
.welcome_content {
  text-align: center;
  color: #fff;
  font-size: 34px;
  position: fixed;
  bottom: 20vh;
  line-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
}
.first_come {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100% auto;
  .logo_class {
    width: 422px;
    height: 88px;
    margin-bottom: 83px;
  }
  .bg-class {
    width: 100%;
    height: 100%;
    object-fit: cover;
    //   left: 0;
    //   right: 0;
    //   // object-fit: cover;
  }
  .welcome_bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .slogan1 {
    width: 290px;
    height: 699px;
    top: 50%;
    margin-top: -350px;
  }
  .slogan2 {
    width: 209px;
    height: 104px;
    bottom: 7vh;
  }
  .top,
  .bottom {
    width: 100%;
    height: auto;
  }
  .top {
    top: 0;
  }
  .bottom {
    bottom: 0px;
    object-fit: cover;
  }
  .first_name {
    font-size: 36px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #333333;
    line-height: 50px;
  }
  .first_title {
    display: flex;
    position: fixed;
    width: 100%;
    padding: 0 70px;
    color: #fff;
    flex-direction: column;
    align-items: center;
    // overflow: hidden;
    .first_title1 {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 96px;
      background: #1994f4;
      border-radius: 105px;
      font-size: 30px;
      color: #ffffff;
      .sign_in {
        margin: 0 47px;
      }
      img {
        width: 360px;
        height: auto;
      }
    }
    .first_title2 {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      color: #999999;
      .protocol {
        color: #1994f4;
        /* text-decoration: underline; */
      }
      .van-checkbox {
      }
      div {
        margin-left: 6px;
      }
    }
  }
}
.my-swipe {
  width: 100%;
  .van-swipe-item {
    width: 100%;
    color: #fff;
    font-size: 20px;
    height: 300px;
    line-height: 300px;
    text-align: center;
    background-color: #39a9ed;
  }
}
.btn_groups {
  display: grid;
  grid-template-columns: 25vw 25vw;
  // grid-template-rows: 100px 100px ;
}
.order_list {
  overflow: scroll;
  background-color: #f7f7f7; /* 浏览器不支持的时候显示 */
  background-image: url("~@/assets/images/home/top.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  position: relative;
  height: 100vh;
  // height: calc(100vh - 100px); //
  // padding-bottom: 200px;
}
.bind_tel_wrap {
  height: 100%;
  .logo {
    position: relative;
    width: 100vw;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 32px;
    color: #ffffff;
    line-height: 38px;
    z-index: 99;
    img {
      height: 112px;
      z-index: 1;
    }
    .back {
      display: flex;
      align-items: center;
      height: 100%;
      position: absolute;
      left: 0;
      z-index: 99;
      padding: 20px;
      img {
        height: 45px;
        font-size: 32px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 45px;
      }
      span:last-child {
        margin-left: 20px;
      }
    }
  }
}
.bind_tel {
  margin: 0 auto;
  padding: 50px 40px;
  width: 690px;
  height: 100%;
  background: #ffffff;
  border-radius: 30px 30px 0px 0px;
}
.user_header {
  width: 610px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  .head_img {
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
    .edit_img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 100px;
      background-color: rgba(0, 0, 0, 0.39);
      overflow: hidden;
      .label {
        width: 100%;
        text-align: center;
        position: absolute;
        bottom: 20px;
        font-size: 28px;
        color: #fff;
      }
    }
  }
  .form_label {
    font-weight: 700;
    font-size: 32px;
    color: #333333;
    line-height: 38px;
  }
  .border_bottom {
    border-bottom: 2px solid #f3f3f3;
  }
  .user_info {
    width: 100%;
    height: 88px;
    background-color: #fff;
    /* flex: 1; */
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 65px;
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    .title,
    .input_title {
      width: 100%;
      font-size: 30px;
      line-height: 80px;
      letter-spacing: 2px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      color: #333333;
      border: none;
      padding: 0;
      background: transparent;
      /* ::placeholder */
      &::placeholder {
        font-size: 30px;
      }

      /* 如果需要兼容性更好，可以添加浏览器前缀 */
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      &::-webkit-input-placeholder {
        font-size: 30px;
      }
    }

    img {
      // background-color: #123abc;

      width: 30px;
      height: 30px;
      margin-left: 30px;
      margin-right: -60px;
    }
  }
  .half_width {
    width: 305px !important;
  }
  .half_width_sm {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    .btn {
      padding: 0;
      border: none;
      font-weight: 400;
      font-size: 30px;
      color: #1994f4;
      line-height: 35px;
    }
    .van-button--plain.van-button--primary {
      font-size: 28px;
      height: 100%;
    }
  }
  .welcome {
    font-size: 36px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #333333;
    line-height: 50px;
  }
  .sub_welcome {
    width: 100%;
    font-weight: 400;
    font-size: 26px;
    color: #1994f4;
    line-height: 30px;
  }
  .tags_list {
    width: 670px;
    // min-height: calc(100vh - 806px);
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;

    .tag_item {
      margin-right: 20px;
      margin-bottom: 20px;
      padding: 0 20px;
      height: 80px;
      background: #ffffff;
      box-shadow: 0px 0px 30px 0px rgba(225, 232, 238, 0.5);
      border-radius: 4px;
      @include display(row, center, center);
      color: #333330;
      font-size: 26px;
    }
    &:last-child {
      // margin-bottom: 0;
    }
    .tag_item.is_active {
      background: #6a83fd;
      box-shadow: 0px 14px 20px 0px rgba(173, 187, 255, 0.43);
      border-radius: 4px;
      color: #ffffff;
    }
  }
  .setting {
    width: 76px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 36px;
      height: 34px;
    }
  }
}

.btn_class {
  width: 610px;
  position: relative;
  bottom: 0;
  display: flex;
  border-radius: 105px 105px 105px 105px;
  overflow: hidden;
  .btn {
    // background-color: $baseColor;
    background: #1994f4;
    border: none;
    width: 100%;
    height: 100px;
  }
  .van-button--plain.van-button--primary {
    color: #ffffff;
    font-size: 30px;
    height: 88px;
    font-family: PingFang SC;
    font-weight: 400;
    border-radius: 4px;
  }
}
</style>
<style lang="scss">
.edit_img {
  .van-uploader {
    width: 100%;
    height: 100%;
    .van-uploader__wrapper {
      width: 100%;
      height: 100%;
      .van-uploader__upload {
        width: 100%;
        height: 100%;
        margin: 0;
        background-color: transparent;
        .van-uploader__upload-icon,
        .van-uploader__upload-text {
          color: #fff;
        }
      }
      .van-uploader__preview {
        width: 100%;
        height: 100%;
        margin: 0;
        .van-uploader__preview-image {
          width: 100%;
          height: 100%;
          margin: 0;
        }
        .van-uploader__preview-delete {
          width: 100%;
          height: 100%;
          background-color: transparent;
        }
      }
    }
  }
}
.login {
  .van-checkbox__label {
    color: #fff;
  }
  .van-dialog3 {
    width: 80%;
    height: 760px;
    border-radius: 24px;
    padding: 30px;
    .vant_title {
      font-size: 32px;
      font-family: SourceHanSansSC-Bold, SourceHanSansSC;
      font-weight: bold;
      color: #181818;
      line-height: 46px;
    }
    .vant_title2 {
      font-size: 26px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #181818;
      overflow-y: scroll;
      height: 540px;
      margin: 20px 0px;
      span {
        line-height: 40px;
      }
    }

    .vant_img {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      z-index: -1;
    }
    .vant_btn {
      width: 90%;
      height: 65px;
      background: linear-gradient(
        180deg,
        #ffee7d 0%,
        #e49f54 100%,
        #e49f54 100%
      );
      border-radius: 38px;
      font-size: 26px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #f9f3f3;
      line-height: 65px;
      letter-spacing: 2px;
      text-align: center;
      position: absolute;
      bottom: 20px;
      left: 24px;
    }
  }
}
</style>
